import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import CaseStudiesDetailsContent from '../../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import banner_img from '../../assets/images/projects/project5.jpg'

const facts = [
    {
        id: 0,
        className: 'postgresql',
        label: 'Autonomous haulage systems, which involve the use of self-driving trucks in mining operations, have been increasingly adopted by mining companies worldwide to improve efficiency, safety, and productivity.',
    },
    {
        id: 1,
        className: 'elasticsearch',
        label: 'AHS can enhance safety in mining operations by reducing the risk of accidents and improving control over heavy machinery, especially in challenging and remote environments.',
    },
    {
        id: 2,
        className: 'opensearch',
        label: 'Autonomous haulage systems can operate continuously, without the need for rest breaks, leading to improved operational efficiency and reduced downtime.',
    },
    {
        id: 3,
        className: 'opensearch',
        label: 'By optimizing routes, speed, and fuel consumption, AHS can result in significant cost savings for mining companies over time.',
    }

];

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case Studies Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Case Studies Details" 
            />
            <CaseStudiesDetailsContent
                banner_img={banner_img}
                facts={facts}
                client={"South African Iron Ore Mine"}
                location={"Johanessburg, South Africa"}
                technologies={"Mining, Iron Ore"}
                completed={"2022"}
                website={"https://neolytics.io"}
                type={"Mining"}
                title={"Autonomous Haulage project from NPV negative to positive"}
                description={"Supported a South African Iron Ore mine with a roadmap to make moving to a fully autonomous (driverless) trucking fleet profitable."}
                important_facts={""}
                long_description1={"We provided invaluable support to a prominent South African iron ore mine by developing a comprehensive roadmap to transition to a fully autonomous (driverless) trucking fleet while ensuring profitability. Our roadmap encompassed a meticulous analysis of the existing mining and logistics operations, taking into account key factors such as terrain, infrastructure, and technology readiness. Through a combination of advanced modeling and industry expertise, we identified critical milestones and recommended the integration of cutting-edge autonomous haulage systems. By carefully aligning this technological transformation with operational efficiency enhancements, safety improvements, and cost optimization strategies, we not only paved the way for a seamless transition to autonomous trucking but also ensured that the transition would yield long-term profitability for the iron ore mine."}
                long_description2={""}
                results={"Published or shared upon request and in compliance with regulations."}
            />
            {/* <RelatedProjects /> */}
            <Footer />
        </Layout>
    );
}

export default CaseDetails